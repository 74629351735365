import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext } from 'react';
import { AuthenticationContext } from '../../../../../authentication-provider/AuthenticationContext';
import { OrderBuilderStore } from '../../OrderBuilderStore';
import { ComoRewardOrderBuilderSegmentGoToRewardsSection } from './ComoRewardOrderBuilderSegmentGoToRewardsSection';
import { ComoRewardOrderBuilderSegmentLoginSection } from './ComoRewardOrderBuilderSegmentLoginSection';

interface ComoRewardOrderBuilderSegmentAccordionSummaryAppendixProps
{
	store: OrderBuilderStore;
}

export const ComoRewardOrderBuilderSegmentAccordionSummaryAppendix: FC<ComoRewardOrderBuilderSegmentAccordionSummaryAppendixProps> =
	({
		store,
	}) =>
	{
		const {authenticationResult} = useContext(AuthenticationContext);

		const hasActiveLoyaltyRewards = useObserver(() => store.appliedRewardsStore?.hasActiveLoyaltyRewards);

		if (authenticationResult.version !== 'V3' || authenticationResult.idTokenPayload.anonymous)
		{
			return <ComoRewardOrderBuilderSegmentLoginSection
				store={store}
			/>;
		}
		else if (!hasActiveLoyaltyRewards)
		{
			return <ComoRewardOrderBuilderSegmentGoToRewardsSection
				store={store}
			/>;
		}
		else
		{
			return null;
		}
	};
