import { PaymentMethods } from '@adyen/adyen-web/dist/types/types';
import * as React from 'react';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { ADYEN_CHECKOUT_APPLE_PAY_TYPE, ADYEN_CHECKOUT_GOOGLE_PAY_TYPE, useAdyenCheckoutContext } from '../AdyenCheckoutContext';
import { useAdyenCheckoutPaymentMethodAvailability } from '../useAdyenCheckoutPaymentMethodAvailability';

type UnavailablePaymentMethodContext = (keyof PaymentMethods)[];

const UnavailablePaymentMethodContextRef = createContext<UnavailablePaymentMethodContext>(undefined as never);

export function useUnavailablePaymentMethodContext()
{
	return useContext(UnavailablePaymentMethodContextRef);
}

export const UnavailablePaymentMethodContextProvider: FC =
	({
		children,
	}) =>
	{
		const {client} = useAdyenCheckoutContext();

		const [
			unavailableAdyenCheckoutPaymentMethodTypes,
			setUnavailableAdyenCheckoutPaymentMethodTypes,
		] = useState<(keyof PaymentMethods)[]>([]);

		const isApplePayAvailable = useAdyenCheckoutPaymentMethodAvailability(client, ADYEN_CHECKOUT_APPLE_PAY_TYPE);
		const isGooglePayAvailable = useAdyenCheckoutPaymentMethodAvailability(client, ADYEN_CHECKOUT_GOOGLE_PAY_TYPE);

		useEffect(() =>
		{
			if (isApplePayAvailable !== undefined && isGooglePayAvailable !== undefined)
			{
				const unavailablePaymentMethodTypeIds: (keyof PaymentMethods)[] = [];

				if (!isApplePayAvailable)
					unavailablePaymentMethodTypeIds.push(ADYEN_CHECKOUT_APPLE_PAY_TYPE);

				if (!isGooglePayAvailable)
					unavailablePaymentMethodTypeIds.push(ADYEN_CHECKOUT_GOOGLE_PAY_TYPE);

				setUnavailableAdyenCheckoutPaymentMethodTypes(unavailablePaymentMethodTypeIds);
			}
			else
			{
				setUnavailableAdyenCheckoutPaymentMethodTypes([]);
			}
		}, [isApplePayAvailable, isGooglePayAvailable]);

		return <UnavailablePaymentMethodContextRef.Provider
			value={unavailableAdyenCheckoutPaymentMethodTypes}
		>
			{children}
		</UnavailablePaymentMethodContextRef.Provider>;
	};
