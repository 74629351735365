import { Grid, makeStyles, Theme } from '@material-ui/core';
import { BaseCSSProperties } from '@material-ui/styles/withStyles/withStyles';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { useRootContext } from '../../../../../RootContext';
import { useIsKioskMode } from '../../../../../Service/KioskService/Api/useIsKioskMode';
import { getTopBarHeight } from '../../../../UI/PageTopBar';
import { SessionStatusBarPlaceholder } from '../../../../UI/session-status-bar/SessionStatusBarPlaceholder';
import { Announcement } from '../../Announcement/Announcement';
import { ComoRewardsStore } from './ComoRewardsStore';

const useStyle = makeStyles<Theme, { isKioskMode: boolean }>(theme => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		marginRight: 0, // to make menu's top bar appear under sidebar's round edges
		overflow: 'hidden',
		paddingRight: 0, // to make menu's top bar appear under sidebar's round edges
	},
	menuGrid: {
		display: 'flex',
		flex: '1 0 auto',
		flexDirection: 'column',
		flexWrap: 'nowrap',
	},
	menuGridListContainer: (({isKioskMode}) =>
	{
		const base: BaseCSSProperties = {
			display: 'flex',
			flex: '1 0 auto',
			flexDirection: 'column',
		};

		const paddingValue = theme.spacing(1);

		const additional: BaseCSSProperties = {
			paddingLeft: paddingValue,
			paddingRight: paddingValue,
			paddingBottom: paddingValue,

			[theme.breakpoints.down('sm')]: {
				marginTop:
					getTopBarHeight(
						true,
						isKioskMode,
					) + paddingValue,
			},
			[theme.breakpoints.up('md')]: {
				marginTop:
					getTopBarHeight(
						false,
						isKioskMode,
					) + paddingValue,
			},
		};

		return {...base, ...additional};
	}),
	menuHeadGridContainer: {
		flex: '0 0 auto !important',
	},
}));

interface MembershipErrorComoRewardsContentProps
{
	store: ComoRewardsStore;
}

export const MembershipErrorComoRewardsContent: FC<MembershipErrorComoRewardsContentProps> =
	({
		store,
	}) =>
	{
		const isKioskMode = useIsKioskMode();
		const classes = useStyle({isKioskMode});
		const {brandingService} = useRootContext(true);

		const announcement = useObserver(() => store.announcement);
		const shouldUseSecondaryColorOnMenu = useObserver(() => brandingService.shouldUseSecondaryColorOnMenu);

		return <div className={classes.container}>
			<div className={classes.menuGrid}>
				<div className={classes.menuGridListContainer}>
					<Grid
						classes={{
							container: classes.menuGrid,
						}}
						container
						spacing={1}
					>
						<Grid
							item
							xs={12}
							classes={{
								item: classes.menuHeadGridContainer,
							}}
						>
							<Announcement
								announcement={announcement}
								useSecondaryColor={shouldUseSecondaryColorOnMenu}
							/>
						</Grid>
					</Grid>
				</div>
			</div>
			<SessionStatusBarPlaceholder />
		</div>;
	};
