import { createContext } from 'react';
import { Account } from '../../Api/Account/Account';
import { AuthenticationResult } from './AuthenticationResult';

export interface AuthenticationContextValue
{
	authenticationResult: AuthenticationResult | undefined;
	didInitialize: boolean;
	initializationError: Error | undefined;
	login: (partnerLogoUrl?: string) => Promise<void>;
	logout: (partnerLogoUrl?: string) => Promise<void>;
	registerAccount: () => Promise<void>;
	verifyAccount: (account: Account) => Promise<void>;
}

export const AuthenticationContext = createContext<AuthenticationContextValue>(undefined!);
