import { Loader } from '@intentic/ts-foundation';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useState } from 'react';
import { useAsyncEffect } from '../../../../../Util/async/useAsyncEffect';
import { ComoRewardsStore } from './ComoRewardsStore';
import { InitializedComoRewardsContent } from './InitializedComoRewardsContent';
import { MembershipErrorComoRewardsContent } from './MembershipErrorComoRewardsContent';
import { MembershipNotFoundComoRewardsContent } from './MembershipNotFoundComoRewardsContent';

interface ComoRewardsContentProps
{
	store: ComoRewardsStore;
}

export const ComoRewardsContent: FC<ComoRewardsContentProps> =
	({
		store,
	}) =>
	{
		const [isInitialized, setIsInitialized] = useState(false);
		const membership = useObserver(() => store.membership);
		const membershipStatusCode = useObserver(() => store.membershipStatusCode);

		useAsyncEffect(
			() =>
				({
					promise: store.refreshMembership(),
					then:
						() =>
							setIsInitialized(true),
				}),
			[store],
		);

		if (membershipStatusCode === 404)
		{
			return <MembershipNotFoundComoRewardsContent store={store} />;
		}
		if (isInitialized && membership !== undefined)
		{
			return <InitializedComoRewardsContent store={store} />;
		}
		else if (isInitialized)
		{
			return <MembershipErrorComoRewardsContent store={store} />;
		}
		else
		{
			return <Loader />;
		}
	};
