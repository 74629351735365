import CssBaseline from '@material-ui/core/CssBaseline';
import * as React from 'react';
import { FC } from 'react';
import { WebClientProvider } from '../../Bridge/Client/WebClientProvider';
import { NotificationSnackbarInitializedRequirer } from '../../Bridge/Notification/Snackbar/NotificationSnackbarInitializedRequirer';
import { NotificationSnackbarProvider } from '../../Bridge/Notification/Snackbar/NotificationSnackbarProvider';
import { RootContextInitializedRequirer, RootContextProvider } from '../../RootContext';
import { KioskServiceProvider } from '../../Service/KioskService/Provider/KioskServiceProvider';
import { TestButtons } from '../../TestButtons';
import { ObjectUrlCacheProvider } from '../../Util/ObjectUrlCache/ObjectUrlCacheProvider';
import { ThemeProvider } from '../../Util/ThemeProvider/ThemeProvider';
import { ApiReachabilitySuspense } from '../api-reachability-suspense/ApiReachabilitySuspense';
import { AppVersionContextProvider } from '../app-version/AppVersionContextProvider';
import { AuthenticationContextProvider } from '../authentication-provider/AuthenticationContextProvider';
import { BrandingServiceProvider } from '../branding-service/BrandingServiceProvider';
import { BrowserRootLayoutPolyfiller } from '../BrowserRootLayoutPolyfiller/BrowserRootLayoutPolyfiller';
import { ClientInstanceUuidProvider } from '../client-instance-uuid/ClientInstanceUuid';
import { CurrentPlaceServiceProvider } from '../current-place-service/CurrentPlaceService';
import { LocalizerProvider } from '../localizer/Localizer';
import { LocationServiceProvider } from '../location-service/LocationService';
import { LoaderPage } from '../Page/LoaderPage';
import { QueryParameterInterceptor } from '../query-parameter-intercepter/QueryParameterInterceptor';
import { RoutingStateProvider } from '../routing-state-provider/RoutingStateProvider';
import { ScreenContainer } from '../screen-container/ScreenContainer';
import { WebSocketConnectionProvider } from '../websocket-connection/WebSocketConnection';
import { StorageContextProvider } from './StorageContextProvider';

export const Root: FC =
	() =>
	{
		return <>
			<CssBaseline />
			<ObjectUrlCacheProvider>
				<StorageContextProvider>
					<KioskServiceProvider>
						<QueryParameterInterceptor>
							<AppVersionContextProvider>
								<WebClientProvider>
									<BrowserRootLayoutPolyfiller>
										<LocalizerProvider>
											<LocationServiceProvider>
												<CurrentPlaceServiceProvider>
													<RoutingStateProvider>
														<BrandingServiceProvider>
															<ThemeProvider>
																<AuthenticationContextProvider>
																	<ClientInstanceUuidProvider>
																		<WebSocketConnectionProvider>
																			<ApiReachabilitySuspense showWhenUnreachable={<LoaderPage />}>
																				<RootContextProvider>
																					<ThemeProvider>
																						<NotificationSnackbarProvider>
																							<RootContextInitializedRequirer showWhenLoading={<LoaderPage />}>
																								<NotificationSnackbarInitializedRequirer showWhenLoading={<LoaderPage />}>
																									<React.Suspense fallback={<LoaderPage />}>
																										<ScreenContainer />
																									</React.Suspense>
																								</NotificationSnackbarInitializedRequirer>
																							</RootContextInitializedRequirer>
																							<TestButtons />
																						</NotificationSnackbarProvider>
																					</ThemeProvider>
																				</RootContextProvider>
																			</ApiReachabilitySuspense>
																		</WebSocketConnectionProvider>
																	</ClientInstanceUuidProvider>
																</AuthenticationContextProvider>
															</ThemeProvider>
														</BrandingServiceProvider>
													</RoutingStateProvider>
												</CurrentPlaceServiceProvider>
											</LocationServiceProvider>
										</LocalizerProvider>
									</BrowserRootLayoutPolyfiller>
								</WebClientProvider>
							</AppVersionContextProvider>
						</QueryParameterInterceptor>
					</KioskServiceProvider>
				</StorageContextProvider>
			</ObjectUrlCacheProvider>
		</>;
	};