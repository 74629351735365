import { Box, Card, CardContent, Checkbox, FormControlLabel, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { BaseCSSProperties } from '@material-ui/styles/withStyles/withStyles';
import { useObserver } from 'mobx-react-lite';
import { FC, useContext, useState } from 'react';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { ComoTermsAndConditionsUrl } from '../../../../../Constants/ComoConstants';
import { useIsKioskMode } from '../../../../../Service/KioskService/Api/useIsKioskMode';
import { getFileUrl } from '../../../../../Util/Api/Resources/getFileUrl';
import { ActionButton } from '../../../../UI/action-button/ActionButton';
import { getTopBarHeight } from '../../../../UI/PageTopBar';
import { SessionStatusBarPlaceholder } from '../../../../UI/session-status-bar/SessionStatusBarPlaceholder';
import { BusinessContextRef } from '../../BusinessContext';
import { ComoRewardsStore } from './ComoRewardsStore';

const useStyle = makeStyles<Theme, { isKioskMode: boolean }>(theme => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		marginRight: 0, // to make menu's top bar appear under sidebar's round edges
		overflow: 'hidden',
		paddingRight: 0, // to make menu's top bar appear under sidebar's round edges
	},
	menuGrid: {
		display: 'flex',
		flex: '1 0 auto',
		flexDirection: 'column',
		flexWrap: 'nowrap',
	},
	menuGridListContainer: (({isKioskMode}) =>
	{
		const base: BaseCSSProperties = {
			display: 'flex',
			flex: '1 0 auto',
			flexDirection: 'column',
		};

		const paddingValue = theme.spacing(1);

		const additional: BaseCSSProperties = {
			paddingLeft: paddingValue,
			paddingRight: paddingValue,
			paddingBottom: paddingValue,

			[theme.breakpoints.down('sm')]: {
				marginTop:
					getTopBarHeight(
						true,
						isKioskMode,
					) + paddingValue,
			},
			[theme.breakpoints.up('md')]: {
				marginTop:
					getTopBarHeight(
						false,
						isKioskMode,
					) + paddingValue,
			},
		};

		return {...base, ...additional};
	}),
	menuHeadGridContainer: {
		flex: '0 0 auto !important',
	},
	termsAndConditionsCardContent: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(4),
	},
	termsAndConditionsCardContentTop: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	termsAndConditionsCardImage: {
		alignSelf: 'center',
		height: '100%',
		margin: theme.spacing(2),
		maxHeight: 100,
		maxWidth: 100,
	},
	termsAndConditionsCardForm: {
		alignSelf: 'center',
	},
}));

interface MembershipNotFoundComoRewardsContentProps
{
	store: ComoRewardsStore;
}

export const MembershipNotFoundComoRewardsContent: FC<MembershipNotFoundComoRewardsContentProps> =
	({
		store,
	}) =>
	{
		const translate = useTranslate();
		const {businessStore} = useContext(BusinessContextRef);
		const isKioskMode = useIsKioskMode();
		const classes = useStyle({isKioskMode});

		const [hasConsent, setHasConsent] = useState(false);

		const loyaltyIntegrationLogoUrl = useObserver(() => businessStore.loyaltyIntegrationLogoUrl);
		const register = useObserver(() => store.register);

		return <div className={classes.container}>
			<div className={classes.menuGrid}>
				<div className={classes.menuGridListContainer}>
					<Grid
						classes={{
							container: classes.menuGrid,
						}}
						container
						spacing={1}
					>
						<Grid
							item
							xs={12}
							classes={{
								item: classes.menuHeadGridContainer,
							}}
						>
							<Card>
								<CardContent
									className={classes.termsAndConditionsCardContent}
								>
									<Box
										className={classes.termsAndConditionsCardContentTop}
									>
										<Box>
											<Typography
												paragraph
												variant="h6"
											>
												{translate('TermsAndConditions')}
											</Typography>
											<Typography>
												{`${translate('Loyalty-TermsAndConditions-Message')} `}
												<a
													href={ComoTermsAndConditionsUrl}
													rel="noopener noreferrer"
													target="_blank"
												>
													{translate('Loyalty-TermsAndConditions-Review')}
												</a>
											</Typography>
										</Box>
										{
											loyaltyIntegrationLogoUrl !== undefined &&
											<img
												alt="loyalty"
												className={classes.termsAndConditionsCardImage}
												src={getFileUrl(loyaltyIntegrationLogoUrl)}
											/>
										}
									</Box>
									<FormControlLabel
										value={hasConsent}
										onChange={
											(_, checked) =>
												setHasConsent(checked)
										}
										className={classes.termsAndConditionsCardForm}
										control={
											<Checkbox
												color="primary"
											/>
										}
										label={translate('Loyalty-TermsAndConditions-Agree')}
									/>
									<Box
										sx={{
											textAlign: 'right',
										}}
									>
										<ActionButton
											onClick={register}
											disabled={!hasConsent}
											icon={CheckIcon}
											text={translate('Generic-Confirm')}
										/>
									</Box>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</div>
			</div>
			<SessionStatusBarPlaceholder />
		</div>;
	};
