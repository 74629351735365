import { BaseStore } from '@intentic/ts-foundation';
import Decimal from 'decimal.js';
import { action, computed, makeObservable, observable } from 'mobx';
import { Announcement } from '../../../../../Api/Business/Announcement';
import { Membership } from '../../../../../Api/Order/Loyalty/Como/Membership';
import { Reward } from '../../../../../Api/Order/Loyalty/Como/Reward';
import { User } from '../../../../../Api/Order/Loyalty/Como/User';
import { WebStorage } from '../../../../../Bridge/Storage/WebStorage';
import { Screens } from '../../../../../Constants/ScreenConstants';
import { fetchAny, performRequest } from '../../../../../Util/Api';
import { getFileUrl } from '../../../../../Util/Api/Resources/getFileUrl';
import { getAccountFromStorage } from '../../../../authentication-provider/getAccountFromStorage';
import { authorize } from '../../../../authentication-provider/v3/authorize';
import { loginLegacy } from '../../../../authentication-provider/v3/loginLegacy';
import { BusinessStore } from '../../BusinessStore';
import { ShoppingCartStore } from '../../ShoppingCart/ShoppingCartStore';
import { ComoRewardStore } from '../Reward/ComoRewardStore';

export class ComoRewardsStore extends BaseStore
{
	// ------------------------ Dependencies ------------------------

	// ------------------------- Properties -------------------------

	public readonly shoppingCartStore: ShoppingCartStore;

	public readonly businessStore: BusinessStore;

	public readonly storage: WebStorage | undefined;

	public tabIndex: number = 0;

	membership: Membership | undefined;

	membershipStatusCode: number | undefined;

	// ------------------------ Constructor -------------------------

	constructor(
		businessStore: BusinessStore,
		shoppingCartStore: ShoppingCartStore,
		storage: WebStorage,
	)
	{
		super();

		makeObservable(
			this,
			{
				tabIndex: observable,
				membership: observable,
				membershipStatusCode: observable,
				announcement: computed,
				rewards: computed,
				rewardsById: computed,
				setMembershipStatusCode: action.bound,
				register: action.bound,
				refreshMembership: action.bound,
				setTabIndex: action.bound,
				setMembership: action.bound,
			},
		);

		this.businessStore = businessStore;
		this.shoppingCartStore = shoppingCartStore;
		this.storage = storage;
	}

	// ----------------------- Initialization -----------------------

	public setMembershipStatusCode(statusCode: number | undefined): void
	{
		this.membershipStatusCode = statusCode;
	}

	async register(): Promise<void>
	{
		try
		{
			this.setMembership(await this.registerMemberDetails());
		}
		catch (e)
		{
			console.error('Could not create membership, Como loyalty rewards are not activated', e);
		}
	}

	async refreshMembership(): Promise<void>
	{
		try
		{
			const memberDetailsResponse = await this.getMemberDetails();
			this.setMembership(memberDetailsResponse);
		}
		catch (e)
		{
			console.error('Could not refresh membership, Como loyalty rewards are not activated', e);
		}
	}

	async registerMemberDetails(): Promise<Membership>
	{
		const response = await performRequest<Membership>(
			'/client/business/loyalty/como/membership/register',
			'post',
			{
				lines: this
					.shoppingCartStore
					.currentOrderService
					.configurations
					.map(configuration => ({
						type: 'product',
						product_id: configuration.product.id,
						quantity: this.shoppingCartStore
							.currentOrderService
							.writeAheadCartContents
							.get(configuration),
						product_variants: configuration
							.variantConfigurations
							.map(variant => ({
								product_feature_variant_id: variant.variant.id,
								product_feature_assignment_id: variant.assignment.id,
							})),
					})),
			},
		);

		this.setMembershipStatusCode(response.status);

		return response.status >= 200 && response.status < 400
			? response.data
			: undefined;
	}

	async getMemberDetails(): Promise<Membership | undefined>
	{
		const response = await performRequest<Membership>(
			'/client/business/loyalty/como/membership',
			'post',
			{
				lines: this
					.shoppingCartStore
					.currentOrderService
					.configurations
					.map(configuration => ({
						type: 'product',
						product_id: configuration.product.id,
						quantity: this.shoppingCartStore
							.currentOrderService
							.writeAheadCartContents
							.get(configuration),
						product_variants: configuration
							.variantConfigurations
							.map(variant => ({
								product_feature_variant_id: variant.variant.id,
								product_feature_assignment_id: variant.assignment.id,
							})),
					})),
			},
		);

		this.setMembershipStatusCode(response.status);

		return response.status >= 200 && response.status < 400
			? response.data
			: undefined;
	}

	async authenticateWithToken(): Promise<void>
	{
		try
		{
			const user = await fetchAny<User>(
				'/client/business/loyalty/como/user',
				{comoToken: this.businessStore.entranceStore.token},
			);

			const account = this.businessStore.authenticationResult === undefined
				? getAccountFromStorage(this.storage)
				: undefined;

			const aid = account === undefined
				? undefined
				: await loginLegacy(account);

			await authorize(
				this.storage,
				false,
				aid,
				false,
				this.businessStore.loyaltyIntegrationLogoUrl,
				user.emailAddress,
				user.userId,
			);
		}
		catch
		{
			console.error('Como token is not valid');
		}
	}

	// -------------------------- Computed --------------------------

	get announcement(): Announcement | undefined
	{
		if (this.membership === undefined)
		{
			const announcement = new Announcement(
				0,
				'standard',
				this.shoppingCartStore.bridge.localizer.translate('Exception-Message-Api-Generic'),
				true,
				false,
				true,
			);

			if (this.businessStore.loyaltyIntegrationLogoUrl !== undefined)
				(announcement as any).rightImageUrl = getFileUrl(this.businessStore.loyaltyIntegrationLogoUrl);

			return announcement;
		}
		else if (this.membershipStatusCode >= 200 && this.membershipStatusCode < 400)
		{
			const points = new Decimal(this.membership!.points).mul(new Decimal(10).pow(-this.shoppingCartStore.currentOrderService.currency.decimalPlaces)).toString();

			const announcement = new Announcement(
				0,
				'standard',
				this.shoppingCartStore.bridge.localizer.translate('Client-Loyalty-Como-Points-Balance-Description', points),
				true,
				false,
				true,
			);

			(announcement as any).title = this.shoppingCartStore.bridge.localizer.translate('Client-Loyalty-Como-Points-Balance');

			if (this.businessStore.loyaltyIntegrationLogoUrl !== undefined)
				(announcement as any).rightImageUrl = getFileUrl(this.businessStore.loyaltyIntegrationLogoUrl);

			return announcement;
		}
		else
		{
			return undefined;
		}
	}

	get rewards(): ReadonlyArray<Reward>
	{
		return this.membership!.rewards;
	}

	setTabIndex(tabIndex: number): void
	{
		this.tabIndex = tabIndex;
	}

	get rewardsById(): ReadonlyMap<string, Reward>
	{
		return this
			.rewards
			.reduce<Map<string, Reward>>((map, element) => map.set(element.id, element), observable.map());
	}

	setMembership(membership: Membership): void
	{
		this.membership = membership;
	}

	// --------------------------- Stores ---------------------------

	// -------------------------- Actions ---------------------------

	// ------------------------ Public logic ------------------------

	openOrderBuilder()
	{
		return this.businessStore.openOrderBuilder();
	}

	openReward(reward: Reward): Promise<ComoRewardStore>
	{
		const comoRewardStore = new ComoRewardStore(
			this,
			reward.id,
		);

		return this.businessStore.bridge.navigator.pushScreen(Screens.ComoReward, comoRewardStore)
			.then(
				() =>
					Promise.resolve(comoRewardStore),
			);
	}

	// ----------------------- Private logic ------------------------
}
