import { Button, Card, CardContent, Typography } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { FC, useCallback } from 'react';
import { useBrandingService } from '../branding-service/BrandingServiceProvider';
import { useWebLocalizer } from '../localizer/Localizer';
import { useStorage } from '../Root/StorageContextProvider';
import { CenteredPageContent } from '../UI/CenteredPageContent';
import { Page } from '../UI/Page';
import { authorize } from './v3/authorize';

export const RefreshTokenPage: FC =
	() =>
	{
		const theme = useTheme();
		const brandingService = useBrandingService();
		const localizer = useWebLocalizer();
		const storage = useStorage();

		const refresh = useCallback(async () =>
		{
			await authorize(storage, false, undefined, true);
		}, [storage]);

		return <Page
			backgroundImageUrl={brandingService.backgroundImageUrl}
			backgroundImageBlur={brandingService.backgroundImageBlur}
			backgroundOverlayColor={brandingService.backgroundColor.css}
			backgroundColor={brandingService.averageColorOfBackground?.css}
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexGrow: 1,
			}}
		>
			<CenteredPageContent
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Card
					style={{
						maxWidth: '320px',
					}}
				>
					<CardContent
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: theme.spacing(2),
						}}
					>
						<Typography
							variant="h6"
						>
							{localizer.translate('Session-Expired-Title')}
						</Typography>
						<Typography>
							{localizer.translate('Session-Expired-Description')}
						</Typography>
						<Button
							fullWidth
							onClick={refresh}
							color="primary"
							variant="contained"
							autoFocus
							style={{
								alignSelf: 'center',
								padding: '10px 20px',
							}}
						>
							{localizer.translate('Refresh-Session')}
						</Button>
					</CardContent>
				</Card>
			</CenteredPageContent>
		</Page>;
	};
