import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Bridge } from './Bridge/Bridge';
import { WebClient } from './Bridge/Client/WebClient';
import { useWebClient } from './Bridge/Client/WebClientProvider';
import { WebDialog } from './Bridge/Dialog/WebDialog';
import { WebHttp } from './Bridge/Http/WebHttp';
import { Linking } from './Bridge/Linking/Linking';
import { WebLinking } from './Bridge/Linking/WebLinking';
import { Localizer } from './Bridge/Localization/Localizer';
import { WebNavigator } from './Bridge/Navigator/WebNavigator';
import { Notification } from './Bridge/Notification/Notification';
import { WebNotification } from './Bridge/Notification/WebNotification';
import { Storage } from './Bridge/Storage/Storage';
import { getRootScreen } from './common';
import { AppVersionContext } from './Component/app-version/AppVersionContext';
import { AuthenticationContext } from './Component/authentication-provider/AuthenticationContext';
import { useBrandingService } from './Component/branding-service/BrandingServiceProvider';
import { BrowserRootLayoutPolyfillContextRef } from './Component/BrowserRootLayoutPolyfiller/BrowserRootLayoutPolyfillContextProvider';
import { useBrowserRootLayoutPolyfillerNavigationListener } from './Component/BrowserRootLayoutPolyfiller/useBrowserRootLayoutPolyfillerNavigationListener';
import { useClientInstanceUuid } from './Component/client-instance-uuid/ClientInstanceUuid';
import { useCurrentPlaceService } from './Component/current-place-service/CurrentPlaceService';
import { useWebLocalizer } from './Component/localizer/Localizer';
import { useLocationService } from './Component/location-service/LocationService';
import { WebSocketService } from './Component/Page/Business/WebSocketService';
import { RootStore } from './Component/Root/RootStore';
import { useStorage } from './Component/Root/StorageContextProvider';
import { useWebSocketConnection } from './Component/websocket-connection/WebSocketConnection';
import { getScreenByIdMap } from './screenByIdMap';
import { BrandingService } from './Service/BrandingInformation/BrandingService';
import { ScaninService } from './Service/EnteringService/ScaninService';
import { useKioskService } from './Service/KioskService/Api/useKioskService';
import { KioskService } from './Service/KioskService/KioskService';
import { ClockService } from './Util/clock/ClockService';
import { IllegalStateException } from './Util/Exception/IllegalStateException';
import { useQueryScreenWidth } from './Util/Hooks/useQueryScreenWidth';

export interface Context
{
	webSocketService: WebSocketService | undefined
	brandingService: BrandingService
	client: WebClient
	clockService: ClockService
	initializationError: Error | undefined
	instanceUuid: string
	linking: Linking
	localizer: Localizer
	translate: (translationKey: string, ...args: string[]) => string
	notification: Notification
	storage: Storage
	navigator: WebNavigator
	bridge: Bridge
	dialog: WebDialog
	screenWidth: Breakpoint | undefined
	kioskService: KioskService
	webSocketIsRegistered: boolean
	rootStoreDidInitialize: boolean
	lowPerformanceMode: boolean
}

const ContextRef = React.createContext<Context | undefined>(undefined);


export const RootContextProvider: FC =
	(
		{
			children,
		}
	) =>
	{
		const {scrollTo} = useContext(BrowserRootLayoutPolyfillContextRef);
		const client = useWebClient(true);
		const storage = useStorage(true);
		const appVersion = useContext(AppVersionContext);
		const {authenticationResult, verifyAccount} = useContext(AuthenticationContext);
		const localizer = useWebLocalizer();
		const clockService = useMemo(() => new ClockService(), []);
		const locationService = useLocationService();
		const currentPlaceService = useCurrentPlaceService();
		const brandingService = useBrandingService();
		const {lowPerformanceMode} = useContext(BrowserRootLayoutPolyfillContextRef);
		const kioskService = useKioskService();
		const scaninService = useMemo(() => new ScaninService(storage, client), [client, storage]);
		const linking = useMemo(() => new WebLinking(), []);
		const notification = useMemo(() => new WebNotification(), []);
		const {
			navigator,
			dialog,
			bridge,
		} = useMemo(() => {
			const navigator = new WebNavigator(client, scrollTo);
			const dialog = new WebDialog(client, navigator);
			const bridge = new Bridge(
				navigator,
				storage,
				linking,
				localizer,
				notification,
				dialog,
				client,
				new WebHttp(client),
				clockService,
			);
			navigator.registerScreen(
				getRootScreen(
					bridge,
					clockService,
					locationService,
					kioskService,
					currentPlaceService,
					brandingService,
					scaninService,
					getScreenByIdMap(appVersion),
					verifyAccount,
					appVersion,
					authenticationResult,
					storage
				)
			);
			navigator.initialize();
			return {
				navigator,
				dialog,
				bridge,
			};
		}, [client, scrollTo, storage, linking, localizer, notification, clockService, locationService, kioskService, currentPlaceService, brandingService, scaninService, appVersion, verifyAccount, authenticationResult]);
		
		const store = useMemo(() => new RootStore(
			bridge,
			brandingService,
		), [brandingService, bridge]);

		const {
			didInitialize: authenticationServiceDidInitialize,
			initializationError: authenticationInitializationError
		}= useContext(AuthenticationContext);

		const [rootStoreDidInitialize, setRootStoreDidInitialize] = useState(false);

		const [initializationError, setInitializationError] = useState<Error>();
		const combinedInitializationError = useMemo(
			() => initializationError ?? authenticationInitializationError,
			[authenticationInitializationError, initializationError],
		);

		const clientInstanceUuid = useClientInstanceUuid();

		const webSocketService = useWebSocketConnection();

		useEffect(
			() => {
				if (!rootStoreDidInitialize
					&& authenticationServiceDidInitialize)
				{
					store.initialize()
						.then(() => setRootStoreDidInitialize(true))
						.catch(error => {
							setInitializationError(error);
							console.error(error);
						})
					// TODO: Cannot be used until cypress is upgraded to ^3.5.0
					// .finally(() => setStoreDidInitialize(true));
				}
			},
			[store, rootStoreDidInitialize, localizer, authenticationServiceDidInitialize],
		);

		const webSocketIsRegistered = useObserver(() => webSocketService?.isRegistered ?? false);

		useBrowserRootLayoutPolyfillerNavigationListener(
			dialog,
			navigator
		);

		const screenWidth = useQueryScreenWidth();

		const translate = useCallback(
			(translationKey: string, ...args: string[]) => localizer.translate(translationKey, ...args),
			[localizer]
		);
		const value =
			useMemo(
				() => ({
					brandingService,
					client,
					clockService,
					initializationError: combinedInitializationError,
					instanceUuid: clientInstanceUuid!,
					linking,
					localizer,
					bridge,
					navigator,
					notification,
					storage,
					translate,
					webSocketService,
					dialog,
					screenWidth,
					rootStoreDidInitialize,
					webSocketIsRegistered,
					kioskService,
					lowPerformanceMode
				}),
				[
					brandingService,
					client,
					clockService,
					combinedInitializationError,
					clientInstanceUuid,
					linking,
					localizer,
					bridge,
					navigator,
					notification,
					storage,
					translate,
					webSocketService,
					dialog,
					screenWidth,
					rootStoreDidInitialize,
					webSocketIsRegistered,
					kioskService,
					lowPerformanceMode,
				]
			);

		return <ContextRef.Provider
			value={value}
		>
			{children}
		</ContextRef.Provider>
	};

export function useRootContext(require?: false): Context | undefined
export function useRootContext(require: true): Context
export function useRootContext(require?: boolean): Context | undefined
export function useRootContext(require?: boolean): Context | undefined
{
	const context = useContext(ContextRef);
	return useMemo(
		() => {
			if (require && context === undefined)
				throw new IllegalStateException(
					'Trying to use useRootContext outside of RootContextProvider'
				);
			return context;
		},
		[context, require],
	);
}

function useIsRootContextFullyInitialized()
{
	const {
		webSocketIsRegistered,
		rootStoreDidInitialize,
		initializationError,
		webSocketService,
		screenWidth
	} = useRootContext(true);
	return useMemo(
		() => webSocketIsRegistered
			&& (rootStoreDidInitialize || initializationError)
			&& webSocketService !== undefined
			&& screenWidth !== undefined,
		[webSocketIsRegistered, rootStoreDidInitialize, initializationError, webSocketService, screenWidth]
	);
}

interface RootContextFullyInitializedRequirerProps
{
	showWhenLoading: React.ReactElement
}

export const RootContextInitializedRequirer: FC<RootContextFullyInitializedRequirerProps> =
	(
		{
			children,
			showWhenLoading,
		}
	) =>
	{
		const isInitialized = useIsRootContextFullyInitialized();
		return isInitialized
			?
			<>{children}</>
			:
			showWhenLoading;
	}

