import { Box, Card, CardContent, ClickAwayListener, Fade, IconButton, makeStyles, Popper, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import clsx from 'clsx';
import ExitToAppIcon from 'mdi-material-ui/ExitToApp';
import LaunchIcon from 'mdi-material-ui/Launch';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext, useMemo, useRef, useState } from 'react';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { getFileUrl } from '../../../../../Util/Api/Resources/getFileUrl';
import { AuthenticationContext } from '../../../../authentication-provider/AuthenticationContext';
import { ActionButton } from '../../../../UI/action-button/ActionButton';
import { BusinessContextRef } from '../../BusinessContext';

const useStyles = makeStyles(theme => ({
	button: {
		alignSelf: ' stretch',
	},
	iconButton: {
		backgroundColor: ' rgba(0, 0, 0, 0.025)',
		color: 'inherit',

		'&:hover': {
			backgroundColor: ' rgba(0, 0, 0, 0.1)',
		},
	},
	iconButtonShake: {
		animationDelay: '2s',
		animation: '$iconButtonShake 2s ease',
	},
	'@keyframes iconButtonShake': {
		'30%': {transform: 'scale(1.2)'},
		'40%, 60%': {transform: 'rotate(-20deg) scale(1.2)'},
		'50%': {transform: 'rotate(20deg) scale(1.2)'},
		'70%': {transform: 'rotate(0deg) scale(1.2)'},
		'100%': {transform: 'scale(1)'},
	},
	loyaltyIntegrationLogo: {
		maxHeight: 100,
		width: 100,
	},
	popper: {
		marginTop: theme.spacing(3),
	},
	popperCardContent: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(2),
	},
	root: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
	},
}));

export const MenuTopBarAppendixProfileButton: FC =
	() =>
	{
		const {authenticationResult, login, logout} = useContext(AuthenticationContext);
		const {businessStore} = useContext(BusinessContextRef);
		const translate = useTranslate();

		const [isPopperOpen, setIsPopperOpen] = useState(false);

		const businessName = useObserver(() => businessStore.business.name);

		const loyaltyIntegrationIconUrl = useObserver(
			() =>
				businessStore.loyaltyIntegrationIconUrl === undefined
					? undefined
					: getFileUrl(businessStore.loyaltyIntegrationIconUrl),
		);

		const loyaltyIntegrationLogoUrl = useObserver(
			() =>
				businessStore.loyaltyIntegrationLogoUrl === undefined
					? undefined
					: getFileUrl(businessStore.loyaltyIntegrationLogoUrl),
		);

		const popperAnchor = useRef();

		const classes = useStyles();

		const name = useMemo(
			() =>
				authenticationResult.version === 'V3'
					? authenticationResult.idTokenPayload.name
					: undefined,
			[authenticationResult],
		);

		const isLoggedIn = authenticationResult.version === 'V3' && !authenticationResult.idTokenPayload.anonymous;

		return <Box
			className={classes.root}
		>
			<IconButton
				className={
					clsx(
						classes.iconButton,
						{
							[classes.iconButtonShake]: !isLoggedIn,
						},
					)
				}
				ref={popperAnchor}
				size="small"
				onClick={
					() =>
						setIsPopperOpen(true)
				}
			>
				{
					(isLoggedIn || loyaltyIntegrationIconUrl === undefined)
						? <PersonIcon
							fontSize="large"
						/>
						: <img
							style={{
								width: 35,
								height: 35,
							}}
							alt="loyalty"
							src={loyaltyIntegrationIconUrl}
						/>
				}
			</IconButton>
			<Popper
				anchorEl={popperAnchor.current}
				className={classes.popper}
				disablePortal
				open={isPopperOpen}
				placement="bottom-end"
				transition
			>
				{
					({TransitionProps}) =>
						<ClickAwayListener
							onClickAway={
								() =>
									setIsPopperOpen(false)
							}
						>
							<Fade
								{...TransitionProps}
								timeout={350}
							>
								<Card
									raised
								>
									<CardContent
										className={classes.popperCardContent}
									>
										{
											isLoggedIn
												? <>
													<Typography
														variant="h6"
													>
														{`${translate('Generic-Welcome')}, ${name}`}
													</Typography>
													<ActionButton
														className={classes.button}
														icon={ExitToAppIcon}
														onClick={
															() =>
																logout(loyaltyIntegrationLogoUrl)
														}
														text={translate('Generic-LogOut')}
													/>
												</>
												: <>
													{
														loyaltyIntegrationLogoUrl === undefined
															? <Typography
																color="textSecondary"
																variant="h6"
															>
																{translate('Client-PlaceSession-WelcomeToBusiness', businessName)}
															</Typography>
															: <>
																<img
																	alt="loyalty"
																	className={classes.loyaltyIntegrationLogo}
																	src={loyaltyIntegrationLogoUrl}
																/>
																<Typography
																	color="textSecondary"
																>
																	{translate('Social-Login-Reward')}
																</Typography>
															</>
													}
													<ActionButton
														className={classes.button}
														icon={LaunchIcon}
														onClick={
															() =>
																login(
																	loyaltyIntegrationLogoUrl,
																)
														}
														text={translate('Generic-LogIn')}
													/>
												</>
										}
									</CardContent>
								</Card>
							</Fade>
						</ClickAwayListener>
				}
			</Popper>
		</Box>;
	};
