import { FC, useCallback, useMemo, useState } from 'react';
import { Account } from '../../../Api/Account/Account';
import { useWebClient } from '../../../Bridge/Client/WebClientProvider';
import { useAsyncEffect } from '../../../Util/async/useAsyncEffect';
import { useStorage } from '../../Root/StorageContextProvider';
import { AuthenticationContext } from '../AuthenticationContext';
import { AuthenticationResult, AuthenticationResultV2 } from '../AuthenticationResult';
import { getAccountFromStorage } from '../getAccountFromStorage';
import { setAuthorizationHeaders } from '../setAuthorizationHeaders';
import { updateSentryScope } from '../updateSentryScope';
import { authorize } from '../v3/authorize';
import { loginLegacy } from '../v3/loginLegacy';
import { authenticateV2 } from './authenticateV2';
import { persistAccountV2 } from './persistAccountV2';
import { registerAccountV2 } from './registerAccountV2';
import { verifyAccountV2 } from './verifyAccountV2';

export const AuthenticationContextProviderV2: FC =
	({
		children,
	}) =>
	{
		const client = useWebClient(true);
		const storage = useStorage(true);
		const [didInitialize, setDidInitialize] = useState(false);
		const [authenticationResult, setAuthenticationResult] = useState<AuthenticationResult | undefined>();
		const [initializationError, setInitializationError] = useState<Error>();

		const onAuthenticationSuccess = useCallback(
			(authenticationResult: AuthenticationResultV2) =>
			{
				updateSentryScope(authenticationResult);
				setAuthorizationHeaders(authenticationResult);
				persistAccountV2(storage, authenticationResult);
				setAuthenticationResult(authenticationResult);
			},
			[storage],
		);

		const login = useCallback(
			async (
				partnerLogoUrl?: string,
			) =>
			{
				const account = getAccountFromStorage(storage);

				const aid = account === undefined
					? undefined
					: await loginLegacy(account);

				await authorize(storage, false, aid, false, partnerLogoUrl);
			},
			[storage],
		);

		const registerAccount = useCallback(
			async () =>
			{
				const account = await registerAccountV2(client);

				onAuthenticationSuccess({
					version: 'V2',
					key: account.key.toString(10),
					token: account.token,
				});
			},
			[client, onAuthenticationSuccess],
		);

		const verifyAccount = useCallback(
			async (account: Account) =>
			{
				const success = await verifyAccountV2(client, account);

				if (success)
				{
					onAuthenticationSuccess({
						version: 'V2',
						key: account.key.toString(10),
						token: account.token,
					});
				}
			},
			[client, onAuthenticationSuccess],
		);

		useAsyncEffect(
			() =>
				({
					promise: authenticationResult === undefined
						? authenticateV2(client, storage)
							.then(
								account =>
									onAuthenticationSuccess({
										version: 'V2',
										key: account.key.toString(10),
										token: account.token,
									}),
							)
						: Promise.resolve(),
					then:
						() =>
							setDidInitialize(true),
					catch:
						error =>
						{
							console.error(`${error}`);
							setInitializationError(error);
						},
				}),
			[authenticationResult, client, onAuthenticationSuccess, storage],
		);

		const contextValue = useMemo(
			() =>
				({
					authenticationResult,
					didInitialize,
					initializationError,
					login,
					logout: () =>
					{
						throw new Error('V2 Authentication does not support account logout');
					},
					registerAccount,
					verifyAccount,
				}),
			[authenticationResult, didInitialize, initializationError, login, registerAccount, verifyAccount],
		);

		return <AuthenticationContext.Provider
			value={contextValue}
		>
			{children}
		</AuthenticationContext.Provider>;
	};
