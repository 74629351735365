import { IconButton, makeStyles } from '@material-ui/core';
import { Heart } from 'mdi-material-ui';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useContext } from 'react';
import { useTranslate } from '../../../../../../Bridge/Localization/useTranslate';
import { Screens } from '../../../../../../Constants/ScreenConstants';
import { useRootContext } from '../../../../../../RootContext';
import { getFileUrl } from '../../../../../../Util/Api/Resources/getFileUrl';
import { AuthenticationContext } from '../../../../../authentication-provider/AuthenticationContext';
import { PageDialog } from '../../../../../UI/PageDialog';
import { OrderBuilder } from '../../OrderBuilder';
import { OrderBuilderStore } from '../../OrderBuilderStore';

const useStyles = makeStyles(theme => ({
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		flexGrow: 1,
	},
	DialogContent: {
		display: 'flex',
	},
	heartShake: {
		animationDelay: '2s',
		animation: '$heartShake 2s ease',
		marginRight: -theme.spacing(1),
	},
	'@keyframes heartShake': {
		'30%': {transform: 'scale(1.2)'},
		'40%, 60%': {transform: 'rotate(-20deg) scale(1.2)'},
		'50%': {transform: 'rotate(20deg) scale(1.2)'},
		'70%': {transform: 'rotate(0deg) scale(1.2)'},
		'100%': {transform: 'scale(1)'},
	},
}));

interface OrderBuilderPageDialogProps
{
	store: OrderBuilderStore;
}

export const OrderBuilderPageDialog: FC<OrderBuilderPageDialogProps> =
	({
		store,
	}) =>
	{
		const {navigator} = useRootContext(true);
		const translate = useTranslate();
		const close = useObserver(() => store.close);
		const classes = useStyles();
		const {authenticationResult} = useContext(AuthenticationContext);

		const parentScreenId = useObserver(() => navigator.currentScreenInstance.parentScreenInstantiation?.screen.id);

		const onOpenPointShop = useCallback(
			async () =>
			{
				await close();

				if (parentScreenId !== Screens.ComoRewards)
					await store.businessStore.openComoRewardsPage();
			},
			[close, parentScreenId, store.businessStore],
		);

		return <PageDialog
			classes={classes}
			title={translate('Client-ShoppingCart-Title')}
			onClose={close}
			headerAppendix={
				<>
					{
						authenticationResult.version === 'V3' && !authenticationResult.idTokenPayload.anonymous &&
						<IconButton
							color="inherit"
							size="small"
							onClick={onOpenPointShop}
							className={classes.heartShake}
						>
							{
								store.businessStore.loyaltyIntegrationIconUrl === undefined
									? <Heart
										fontSize="large"
									/>
									: <img style={{
										width: 35,
										height: 35,
									}}
										   alt="loyalty"
										   src={getFileUrl(store.businessStore.loyaltyIntegrationIconUrl)}>
									</img>
							}
						</IconButton>
					}
				</>
			}
		>
			<OrderBuilder
				store={store}
				flexGrow
			/>
		</PageDialog>;
	};
