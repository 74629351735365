import UIElement from '@adyen/adyen-web/dist/types/components/UIElement';
import Core from '@adyen/adyen-web/dist/types/core';
import { BaseStore } from '@intentic/ts-foundation';
import Decimal from 'decimal.js';
import { toSeconds } from 'iso8601-duration';
import { action, computed, flow, IObservableValue, makeObservable, observable, ObservableMap } from 'mobx';
import * as URI from 'urijs';
import { Announcement } from '../../../Api/Business/Announcement';
import { Business } from '../../../Api/Business/Business';
import { BusinessEntrance, BusinessEntranceProfile } from '../../../Api/Business/BusinessEntrance';
import { Place } from '../../../Api/Business/Place';
import { PlaceSession } from '../../../Api/Business/PlaceSession';
import { ContractingEntity } from '../../../Api/contracting_entity/ContractingEntity';
import { Cart } from '../../../Api/Order/Cart/Cart';
import { Order } from '../../../Api/Order/Order';
import { NutritionFlag } from '../../../Api/Product/NutritionFlag';
import { Product } from '../../../Api/Product/Product';
import { ProductCategory } from '../../../Api/Product/ProductCategory';
import { ProductCategoryProfile } from '../../../Api/Product/ProductCategoryProfile';
import { ProductConfiguration } from '../../../Api/Product/ProductConfiguration';
import { ProductFeature } from '../../../Api/Product/ProductFeature';
import { ProductFeatureAssignment } from '../../../Api/Product/ProductFeatureAssignment';
import { ProductFeatureVariant } from '../../../Api/Product/ProductFeatureVariant';
import { ProductFee } from '../../../Api/Product/ProductFee';
import { ProductRecommendationList } from '../../../Api/Product/ProductRecommendationList';
import { ProductRecommendationMoment } from '../../../Api/Product/recommendation/ProductRecommendationMoment';
import { TimeSchedule } from '../../../Api/Util/time-schedule/TimeSchedule';
import { isTrueAtInCache } from '../../../Api/Util/time-series/BooleanTimeSeries/BooleanTimeSeriesCache';
import postWindowEvent from '../../../Api/WindowEvent/Api/postWindowEvent';
import { OrderedWithoutPaymentWindowEvent } from '../../../Api/WindowEvent/Model/OrderedWithoutPaymentWindowEvent';
import { OrderPaymentFailedWindowEvent } from '../../../Api/WindowEvent/Model/OrderPaymentFailedWindowEvent';
import { OrderPaymentStartedWindowEvent } from '../../../Api/WindowEvent/Model/OrderPaymentStartedWindowEvent';
import { OrderPaymentSucceededWindowEvent } from '../../../Api/WindowEvent/Model/OrderPaymentSucceededWindowEvent';
import { Bridge } from '../../../Bridge/Bridge';
import { PinCodeDialogInputSpec } from '../../../Bridge/Dialog/Input/PinCodeDialogInputSpec';
import { ScreenInstantiation } from '../../../Bridge/Navigator/ScreenInstantiation';
import { WebNavigator } from '../../../Bridge/Navigator/WebNavigator';
import { WebStorage } from '../../../Bridge/Storage/WebStorage';
import { Screens } from '../../../Constants/ScreenConstants';
import { ProductMutation } from '../../../lib/event/product/mutation/ProductMutation';
import { ProductPriceMutation } from '../../../lib/event/product/mutation/update/ProductPriceMutation';
import { ProductQuantityMutation } from '../../../lib/event/product/mutation/update/ProductQuantityMutation';
import { ProductFeatureVariantMutation } from '../../../lib/event/product_feature_variant/mutation/ProductFeatureVariantMutation';
import { ProductFeatureVariantPriceMutation } from '../../../lib/event/product_feature_variant/mutation/update/ProductFeatureVariantPriceMutation';
import { ProductFeatureVariantQuantityMutation } from '../../../lib/event/product_feature_variant/mutation/update/ProductFeatureVariantQuantityMutation';
import { CurrentAgeVerificationService } from '../../../Service/CurrentAgeVerification/CurrentAgeVerificationService';
import { CurrentOrderService } from '../../../Service/CurrentOrder/CurrentOrderService';
import { CurrentPlaceService } from '../../../Service/CurrentPlace/CurrentPlaceService';
import { ScaninService } from '../../../Service/EnteringService/ScaninService';
import { placeProductOrder } from '../../../Service/OrderService/Api/Client/placeProductOrder';
import { OrderService } from '../../../Service/OrderService/OrderService';
import { fetch, post } from '../../../Util/Api';
import { getBackendOSValue } from '../../../Util/Api/getBackendOSValue';
import { confirm } from '../../../Util/Dialog';
import { IllegalArgumentException } from '../../../Util/Exception/IllegalArgumentException';
import { hasPayment } from '../../../Util/Orders/hasPayment';
import { StoredVariable } from '../../../Util/StoredVariable';
import { AuthenticationResult } from '../../authentication-provider/AuthenticationResult';
import { OrderPaymentProcessingResult } from '../../UI/payment/model/OrderPaymentProcessingResult';
import { SessionStatusBarStore } from '../../UI/session-status-bar/SessionStatusBarStore';
import { EntranceStore } from '../Entrance/EntranceStore';
import { ProfileStore } from '../Profile/ProfileStore';
import { ComoRewardsStore } from './Como/Rewards/ComoRewardsStore';
import { HistoryStore } from './History/HistoryStore';
import { MenuStore } from './Menu/MenuStore';
import { OrderBuilderStore } from './OrderBuilder/OrderBuilderStore';
import { ProductRecommendationStore } from './Product/ProductRecommendation/ProductRecommendationStore';
import { ProductStore } from './Product/ProductStore';
import { ShoppingCartStore } from './ShoppingCart/ShoppingCartStore';

const HARD_CODED_PIN_CODE: string = '1397';

/**
 * Order reload interval when locked for payment terminal
 */
const ORDER_RELOAD_INTERVAL = 5000;

export class BusinessStore extends BaseStore
{
    /*---------------------------------------------------------------*
     *                          Properties                           *
     *---------------------------------------------------------------*/

    readonly bridge: Bridge;
    readonly entranceStore: EntranceStore;
    readonly currentPlaceService: CurrentPlaceService;
    readonly placeHash: string;
    readonly onLeaveCallback: () => Promise<void>;
    readonly openedCategoryIds: number[] = [];
    readonly productCategoryById = observable.map<number, ProductCategory>();
    readonly productById = observable.map<number, Product>();
    readonly productFeatureById = observable.map<number, ProductFeature>();
    readonly productFeatureAssignmentById = observable.map<number, ProductFeatureAssignment>();
    readonly productFeatureVariantById = observable.map<number, ProductFeatureVariant>();
    readonly productFeeById = observable.map<number, ProductFee>();
    readonly productRecommendationListsByProductId = observable.map<number, ObservableMap<number, ProductRecommendationList>>();
    readonly authenticationResult: AuthenticationResult;
    currentOrderService: CurrentOrderService | undefined;
    currentAgeVerificationService: CurrentAgeVerificationService | undefined;
    comoRewardsStore: ComoRewardsStore | undefined;
    historyStore: HistoryStore;
    rootCategory: ProductCategory;
    contractingEntity: ContractingEntity | undefined;
    loyaltyIntegration: 'COMO' | 'PIGGY' | undefined;
    loyaltyIntegrationIconUrl: string | undefined;
    loyaltyIntegrationLogoUrl: string | undefined;
    announcements: Announcement[];

    /**
     * If {@code true}, the user will not be able to access any order history, and the {@link OrderBuilder} will
     * only close after an order has been paid.
     */
    readonly kioskMode: boolean;
    readonly orderService: OrderService;
    menuStore: MenuStore | undefined;
    public shoppingCartStore: ShoppingCartStore | undefined;
    readonly profileStore: ProfileStore;
    sessionStatusBarStore: SessionStatusBarStore;
    isSlidePanelOpen = false;
    readonly isMenuOpen: IObservableValue<boolean>;
    orderBuilderStore: OrderBuilderStore | undefined;
    initialCart: Cart | undefined;
    readonly pop: (force?: boolean, uuidOfScreenInstantiationToPop?: string) => Promise<any>;
    readonly push: (screenId: string, store: BaseStore) => Promise<ScreenInstantiation>;
    public storage: WebStorage | undefined;

    doShowPaymentMethodSelection: boolean;

    /**
     * The current {@link PlaceSession} on the current {@link Place}. Is always `undefined` when
     * {@link place} is `undefined`, but can still be `undefined` when {@link place} is not `undefined`.
     */
    placeSession: PlaceSession | undefined;

    /**
     * If set to `true`, the {@link Client} will see a welcome message instead of the menu unless a
     * {@link PlaceSession} is open.
     */
    readonly placeSessionRequired: StoredVariable<boolean>;

    private readonly scaninService: ScaninService;

    public timeScheduleById: ObservableMap<number, TimeSchedule>;

    private static orderReloadInterval;

    public checkoutClient?: Core;

    public handlePaymentProcessingResult?: (result: OrderPaymentProcessingResult) => void;

    public initiatePayment?: (order: Order, component?: UIElement<any>) => void;

    /*---------------------------------------------------------------*
     *                          Constructor                          *
     *---------------------------------------------------------------*/

    constructor(
        bridge: Bridge,
        entranceStore: EntranceStore,
        currentPlaceService: CurrentPlaceService,
        scaninService: ScaninService,
        profileStore: ProfileStore,
        business: Business,
        place: Place,
        placeHash: string,
        onLeaveCallback: () => Promise<void>,
        isMenuOpen: IObservableValue<boolean>,
        pop: (force?: boolean, uuidOfScreenInstantiationToPop?: string) => Promise<any>,
        push: (screenId: string, store: BaseStore) => Promise<ScreenInstantiation>,
        openedCategoryIds: number[] = [],
        orderService: OrderService,
        authenticationResult: AuthenticationResult,
        storage: WebStorage
    )
    {
        super();

        makeObservable(
            this,
            {
                currentOrderService: observable,
                currentAgeVerificationService: observable,
                comoRewardsStore: observable,
                historyStore: observable,
                rootCategory: observable,
                announcements: observable,
                menuStore: observable,
                shoppingCartStore: observable,
                sessionStatusBarStore: observable,
                isSlidePanelOpen: observable,
                orderBuilderStore: observable,
                initialCart: observable,
                doShowPaymentMethodSelection: observable,
                placeSession: observable,
                timeScheduleById: observable,
                checkoutClient: observable,
                handlePaymentProcessingResult: observable,
                initiatePayment: observable,
                business: computed,
                place: computed,
                shouldShowNoOpenPlaceSessionScreen: computed,
                shouldShowLockedForPaymentTerminalScreen: computed,
                announcementsForCurrentPlace: computed,
                productAnnouncements: computed,
                openedChildCategories: computed,
                productByUuid: computed,
                productByExternalId: computed,
                productByScanCode: computed,
                productFeatureVariantByUuid: computed,
                setTimeSchedules: action.bound,
                showPaymentMethodSelection: action.bound,
                hidePaymentMethodSelection: action.bound,
                openPage: action.bound,
                afterEnter: action.bound,
                afterFetchTree: action.bound,
                createMenuStore: action.bound,
                setRootCategory: action.bound,
                openProduct: action.bound,
                orderProductConfiguration: action.bound,
                openProductRecommendations: action.bound,
                openProductRecommendationsIfPresent: action.bound,
                addProductToShoppingCart: action.bound,
                startOrderPayment: action.bound,
                onOrder: action.bound,
                resetNavigationAndOpenActiveOrders: action.bound,
                onCommandLeave: action.bound,
                openSlidePanel: action.bound,
                closeSlidePanel: action.bound,
                setSlidePanelOpened: action.bound,
                toggleMenuOpen: action.bound,
                setMenuOpen: action.bound,
                openMenu: action.bound,
                closeMenu: action.bound,
                openOrderBuilder: action.bound,
                openComoRewardsPage: action.bound,
                callback: action.bound,
                openHistory: action.bound,
                openProfile: action.bound,
                orderWaiter: action.bound,
                orderBill: action.bound,
                hasAllergen: action.bound,
                needsToSeeNutritionFlag: action.bound,
                openChildCategory: action.bound,
                setPlaceSession: action.bound,
                setCheckoutClient: action.bound,
                setHandlePaymentProcessingResult: action.bound,
                setInitiatePayment: action.bound,
                mutateProduct: action.bound,
                mutateProductFeatureVariant: action.bound,
            },
        )

        this.bridge = bridge;
        this.entranceStore = entranceStore;
        this.currentPlaceService = currentPlaceService;
        this.scaninService = scaninService;
        this.isMenuOpen = isMenuOpen;
        this.placeHash = placeHash;
        this.onLeaveCallback = onLeaveCallback;
        this.pop = pop;
        this.push = push;
        this.openedCategoryIds = openedCategoryIds;
        this.kioskMode = false;
        this.placeSessionRequired = entranceStore.placeSessionRequired;
        this.timeScheduleById = observable.map<number, TimeSchedule>();
        this.authenticationResult = authenticationResult;

        this.storage = storage

        this.profileStore = profileStore;
        this.orderService = orderService;
        this.orderService
            .setOnUpdate(
                (newVersionOfOrder, oldVersionOfOrder) =>
                {
                    if (oldVersionOfOrder)
                    {
                        if (oldVersionOfOrder.state !== newVersionOfOrder.state)
                        {
                            if (newVersionOfOrder.state === 'acknowledged')
                            {
                                this.bridge.notification.notify(
                                    {
                                        content: this.bridge.localizer.translate('Client-Notification-Order-IsAcknowledged'),
                                    });
                            }
                            else if (newVersionOfOrder.state === 'delivered')
                            {
                                this.bridge.notification.notify(
                                    {
                                        content: this.bridge.localizer.translate('Client-Notification-Order-IsDelivered'),
                                    });
                            }
                            else if (newVersionOfOrder.state === 'pickedUp')
                            {
                                this.bridge.notification.notify(
                                    {
                                        content: this.bridge.localizer.translate('Client-Notification-Order-IsPickedUp'),
                                    });
                            }
                            else if (newVersionOfOrder.state === 'prepared')
                            {
                                this.bridge.notification.notify(
                                    {
                                        content: this.bridge.localizer.translate('Client-Notification-Order-IsPrepared'),
                                    });
                            }
                            else if (newVersionOfOrder.state === 'handled')
                            {
                                this.bridge.notification.notify(
                                    {
                                        content: this.bridge.localizer.translate('Client-Notification-Order-IsHandled'),
                                    });
                            }
                            else if (newVersionOfOrder.state === 'voided')
                            {
                                this.bridge.notification.notify(
                                    {
                                        content: this.bridge.localizer.translate('Client-Notification-Order-IsVoided'),
                                    });
                            }
                        }

                        if (oldVersionOfOrder.paymentState !== newVersionOfOrder.paymentState)
                        {
                            if (newVersionOfOrder.paymentState === 'negotiated')
                            {
                                postWindowEvent(new OrderPaymentStartedWindowEvent(newVersionOfOrder));
                                this.bridge.notification.notify(
                                    {
                                        content: this.bridge.localizer.translate('Client-Notification-Order-Payment-IsNegotiated'),
                                    });
                            }
                            else if (newVersionOfOrder.paymentState === 'paid')
                            {
                                postWindowEvent(new OrderPaymentSucceededWindowEvent(newVersionOfOrder));
                                this.bridge.notification.notify(
                                    {
                                        content: this.bridge.localizer.translate('Client-Notification-Order-Payment-IsPaid'),
                                    });
                            }
                            else if (newVersionOfOrder.paymentState === 'failed')
                            {
                                postWindowEvent(new OrderPaymentFailedWindowEvent(newVersionOfOrder));
                                this.bridge.notification.notify(
                                    {
                                        content: this.bridge.localizer.translate('Client-Notification-Order-Payment-IsFailed'),
                                    });
                            }
                        }
                    }
                    else if (!hasPayment(newVersionOfOrder))
                    {
                        postWindowEvent(new OrderedWithoutPaymentWindowEvent(newVersionOfOrder));
                    }
                }
            );

        this.initializeExitBusinessOnIdle();

        this.bridge.navigator.addScreenTransitionCallback(Screens.Business, this.callback);

        this.currentPlaceService.setScannedBusinessAndPlace(business, place);
    }

    /*---------------------------------------------------------------*
     *                        Initialization                         *
     *---------------------------------------------------------------*/

    async initialize(): Promise<void>
    {
        if (!this.isInitialized)
        {
            const businessEntrance = await post(
                '/client/business/enter',
                {
                    forceRenewCart: this.entranceStore.clearShoppingCartAfterReturning.get(),
                    hash: this.placeHash,
                },
                BusinessEntranceProfile,
            );

            await this.afterEnter(businessEntrance);

            this.setInitialized(true);
        }
    }

    /*---------------------------------------------------------------*
     *                           Computed                            *
     *---------------------------------------------------------------*/

    get business(): Business
    {
        return this.currentPlaceService.business!;
    }

    get place(): Place
    {
        return this.currentPlaceService.place!;
    }

    get shouldShowNoOpenPlaceSessionScreen(): boolean
    {
        return this.placeSessionRequired.value
            && (this.placeSession === null
            || this.placeSession === undefined
            || this.placeSession.endDate !== undefined);
    }

    get shouldShowLockedForPaymentTerminalScreen(): boolean
    {
        return this.orderBuilderStore !== undefined
            && this.orderBuilderStore.inTerminalPayment;
    }

    get announcementsForCurrentPlace(): Announcement[]
    {
        const currentMinute = this.bridge.clockService.currentMinute;

        return this.announcements
            .filter(announcement =>
            {
                const timeSchedule = announcement.visibleAtTimeScheduleId === undefined
                    ? announcement.visibleAt
                    : this.timeScheduleById.get(announcement.visibleAtTimeScheduleId)?.booleanTimeSeries ?? announcement.visibleAt;

                return isTrueAtInCache(timeSchedule, currentMinute);
            });
    }

    get productByUuid(): Map<string, Product>
    {
        const map = observable.map();

        Array
            .from(this.productById.values())
            .forEach(product => map.set(product.uuid, product));

        return map;
    }

    get productByExternalId(): Map<string, Product>
    {
        const map = observable.map();

        Array
            .from(this.productById.values())
            .forEach(product => map.set(product.externalId, product));

        return map;
    }

    get productByScanCode(): Map<string, Product>
    {
        const map = observable.map();

        Array
            .from(this.productById.values())
            .filter(product => product.scanCode !== undefined)
            .forEach(product => map.set(product.scanCode, product));

        return map;
    }

    get productFeatureVariantByUuid(): Map<string, ProductFeatureVariant>
    {
        const map = observable.map();

        Array
            .from(this.productFeatureVariantById.values())
            .forEach(productFeatureVariant => map.set(productFeatureVariant.uuid, productFeatureVariant));

        return map;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    mutateProduct(mutation: ProductMutation): void
    {
        if (this.productByUuid.has(mutation.productId))
        {
            const product = this.productByUuid.get(mutation.productId)!;

            switch (mutation.type)
            {
                case 'ProductPriceMutation':
                    product.setPrice(new Decimal((mutation as ProductPriceMutation).price));
                    break;
                case 'ProductQuantityMutation':
                    product.setQuantity((mutation as ProductQuantityMutation).quantity);
                    break;
                default:
                    return;
            }

            this.productById.set(product.id, product);
        }
    }

    mutateProductFeatureVariant(mutation: ProductFeatureVariantMutation): void
    {
        if (this.productFeatureVariantByUuid.has(mutation.productFeatureVariantId))
        {
            const productFeatureVariant = this.productFeatureVariantByUuid.get(mutation.productFeatureVariantId)!;

            switch (mutation.type)
            {
                case 'ProductFeatureVariantPriceMutation':
                    productFeatureVariant.setPrice(new Decimal((mutation as ProductFeatureVariantPriceMutation).price));
                    break;
                case 'ProductFeatureVariantQuantityMutation':
                    productFeatureVariant.setQuantity((mutation as ProductFeatureVariantQuantityMutation).quantity);
                    break;
                default:
                    return;
            }

            this.productFeatureVariantById.set(productFeatureVariant.id, productFeatureVariant);
        }
    }

    setCheckoutClient(checkoutClient: Core | undefined): void
    {
        this.checkoutClient = checkoutClient;
    }

    setHandlePaymentProcessingResult(handlePaymentProcessingResult: (result: OrderPaymentProcessingResult) => void): void
    {
        this.handlePaymentProcessingResult = handlePaymentProcessingResult;
    }

    setInitiatePayment(initiatePayment: (order: Order, component?: UIElement<any>) => void): void
    {
        this.initiatePayment = initiatePayment;
    }

    setTimeSchedules(timeSchedules?: TimeSchedule[]): void
    {
        this.timeScheduleById.clear();

        timeSchedules
            ?.forEach(timeSchedule => this.timeScheduleById.set(timeSchedule.id, timeSchedule));
    }

    public getProductRecommendationLists(triggeringProductId: number): ProductRecommendationList[]
    {
        return Array.from(this.productRecommendationListsByProductId.get(triggeringProductId)?.keys() ?? [])
            .sort()
            .map(orderNumber => this.productRecommendationListsByProductId.get(triggeringProductId)!.get(orderNumber))
    }

    showPaymentMethodSelection()
    {
        this.doShowPaymentMethodSelection = true;
    }

    hidePaymentMethodSelection()
    {
        this.doShowPaymentMethodSelection = false;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    private reloadOrders(): void
    {
        if (this.shouldShowLockedForPaymentTerminalScreen)
        {
            this.orderService.initialize();
        }
    }

    // -------------------------- Computed --------------------------

    get productAnnouncements(): Announcement[]
    {
        return this
            .announcementsForCurrentPlace
            .filter(announcement => announcement.isDisplayedInProduct);
    }

    get openedChildCategories(): ProductCategory[]
    {
        if (this.menuStore)
        {
            return this.menuStore.getOpenedCategories();
        }
        else
        {
            return [];
        }
    }

    // -------------------------- Actions ---------------------------

    openPage(pageId: string)
    {
        switch (pageId)
        {
            case 'Service':
                return Promise.resolve();
            case 'OrderHistory':
                return this.openHistory(true);
            case 'ShoppingCart':
                return this.openOrderBuilder();
            case 'Profile':
                return this.openProfile();
        }
    }

    async afterEnter(businessEntrance: BusinessEntrance): Promise<void>
    {
        this.contractingEntity = businessEntrance.contractingEntity;
        this.loyaltyIntegration = businessEntrance.loyaltyIntegration;
        this.loyaltyIntegrationIconUrl = businessEntrance.loyaltyIntegrationIconUrl;
        this.loyaltyIntegrationLogoUrl = businessEntrance.loyaltyIntegrationLogoUrl;
        this.announcements = businessEntrance.announcements;
        this.initialCart = businessEntrance.cart;
        this.placeSession = businessEntrance.placeSession;

        this.populateProductRecommendationListsByProductId(businessEntrance);

        this.setRootCategory(
            await fetch(
                '/client/business/product/tree',
                {
                    visit_id: businessEntrance.visitId,
                },
                ProductCategoryProfile,
            )
        );

        await this.afterFetchTree(businessEntrance);
    }

    private populateProductRecommendationListsByProductId(businessEntrance: BusinessEntrance)
    {
        const populate = (triggeringProductId: number, orderNumber: number, productRecommendationList: ProductRecommendationList) => {
            if (!this.productRecommendationListsByProductId.has(triggeringProductId))
                this.productRecommendationListsByProductId.set(triggeringProductId, observable.map());

            // const productRecommendationListCopy = deepCopyProductRecommendationList(productRecommendationList);
            // productRecommendationListCopy.orderNumber = orderNumber;
            this.productRecommendationListsByProductId
                .get(triggeringProductId)!
                .set(orderNumber, productRecommendationList);
        };
        businessEntrance.productRecommendationLists
            .forEach(productRecommendationList => {
                const {
                    productProductRecommendationLists,
                    triggeringProductId,
                } = productRecommendationList;

                if (productProductRecommendationLists !== undefined)
                {
                    productProductRecommendationLists.forEach(productProductRecommendationList => populate(
                        productProductRecommendationList.triggeringProductId,
                        productProductRecommendationList.orderNumber,
                        productRecommendationList
                    ))
                }
                else
                {
                    populate(
                        triggeringProductId,
                        productRecommendationList.orderNumber,
                        productRecommendationList
                    );
                }
            });
    }

    async afterFetchTree(businessEntrance: BusinessEntrance): Promise<void>
    {
        this.currentOrderService = new CurrentOrderService(
            this.bridge,
            this,
            this.currentPlaceService,
            this.rootCategory,
            this.openProduct,
            this.announcementsForCurrentPlace,
            this.productById,
            this.productCategoryById,
            this.productFeatureAssignmentById,
            this.productFeatureVariantById,
            this.timeScheduleById,
            this.entranceStore.clearOrderOptionsAfterOrder,
            this.entranceStore.externalShopperNotificationId,
            this.entranceStore.externalShopperCardId,
            this.entranceStore.isKioskMode.get(),
            this.entranceStore.orderComment,
            this.entranceStore.orderDestinationAddress
        );

        if (businessEntrance.orderRestrictions !== undefined)
        {
            this.currentOrderService
                .setRestrictionReports(businessEntrance.orderRestrictions);
        }

        this.shoppingCartStore = new ShoppingCartStore(
            this.bridge,
            this.currentOrderService,
            this.currentPlaceService,
            this,
            this.openProduct,
        );

        this.currentAgeVerificationService =
            new CurrentAgeVerificationService(
                this.bridge,
                this.currentPlaceService,
                this.shoppingCartStore
            );

        if (this.loyaltyIntegration === 'COMO' && this.authenticationResult.version === 'V3')
        {
            if (!this.authenticationResult.idTokenPayload.anonymous)
            {
                this.comoRewardsStore = new ComoRewardsStore(this, this.shoppingCartStore, this.storage);
                await this.comoRewardsStore.refreshMembership();
            }
            else if (this.entranceStore.token)
            {
                this.comoRewardsStore = new ComoRewardsStore(this, this.shoppingCartStore, this.storage);
                await this.comoRewardsStore.authenticateWithToken();
            }
        }

        this.orderBuilderStore = await this.createOrderBuilderStore(this.shoppingCartStore);

        this.menuStore = this.createMenuStore(this.rootCategory);

        await this.currentOrderService.processNewCartFromServer(this.initialCart, new Date());

        this.historyStore = new HistoryStore(
            this.bridge,
            this.place,
            this.entranceStore.brandingService,
            this.orderService,
            this.productFeatureVariantById,
            this.productFeatureAssignmentById,
            this.pop,
            this.openOrderBuilder,
            this.productById,
            this.openProduct,
            this.addProductToShoppingCart,
            this.pop,
            this.push
        );

        if (BusinessStore.orderReloadInterval)
        {
            clearInterval(BusinessStore.orderReloadInterval);
        }
        BusinessStore.orderReloadInterval = setInterval(
            () => this.reloadOrders(),
            ORDER_RELOAD_INTERVAL
        );

        const url = await this.bridge.linking.getInitialUrl();
        if (url != null)
        {
            const uri = URI.parse(url);
            const params = URI.parseQuery(uri.query) as any;

            if (uri.hostname === 'business' && params.page)
            {
                await this.openPage(params.page);
            }
        }
    }

    createMenuStore(category: ProductCategory, parentStore?: MenuStore): MenuStore
    {
        return new MenuStore(
            this.bridge,
            this.entranceStore,
            this,
            this.business,
            this.place,
            this.entranceStore.brandingService,
            this.createMenuStore,
            this.shoppingCartStore,
            category,
            parentStore ? parentStore.category : undefined,
            this.hasAllergen,
            this.needsToSeeNutritionFlag,
            this.openHistory,
            this.openOrderBuilder,
            this.openProduct,
            this.openProductRecommendations,
            this.orderBill,
            this.orderWaiter,
            parentStore ? parentStore : undefined,
            parentStore ? parentStore.depth + 1 : 0,
            this.openedCategoryIds,
            this.productCategoryById,
            this.productById,
        );
    }

    setRootCategory(rootCategory: ProductCategory)
    {
        rootCategory.initialize(
            this.productCategoryById,
            this.productById,
            this.productFeatureById,
            this.productFeatureAssignmentById,
            this.productFeatureVariantById,
            this.productFeeById,
            undefined
        );

        this.rootCategory = rootCategory;
    }

    openProduct(
        product: Product,
        doPushScreen: boolean = true
    ): Promise<ProductStore>
    {
        const productStore = new ProductStore(
            this.bridge,
            this.entranceStore.profileService,
            this,
            this.business,
            this.place,
            product,
            this.orderProductConfiguration,
            this.productCategoryById,
            this.timeScheduleById,
            productConfiguration => this.openProductRecommendationsIfPresent(
                productConfiguration.product.id
            ),
            () => this.bridge.navigator.popScreen().then(() => {}),
            true,
            this.entranceStore.isKioskMode.get()
        );

        if (doPushScreen)
        {
            return this.push(Screens.Product, productStore)
                .then(
                    () =>
                        Promise.resolve(productStore)
                );
        }
        else
        {
            return Promise.resolve(productStore);
        }
    }

    async orderProductConfiguration(productConfiguration: ProductConfiguration, amount: number = 1): Promise<void>
    {
        if (productConfiguration.product.isDirectOrder)
        {
            await confirm(
                this.bridge,
                (this.bridge.navigator as WebNavigator).scrollTo,
                this.bridge.localizer.translate('Client-Order-DirectOrder-Confirmation'),
                async () =>
                {
                    const currentOrderService = new CurrentOrderService(
                        this.bridge,
                        this,
                        this.currentPlaceService,
                        this.rootCategory,
                        this.openProduct,
                        this.announcementsForCurrentPlace,
                        this.productById,
                        this.productCategoryById,
                        this.productFeatureAssignmentById,
                        this.productFeatureVariantById,
                        this.timeScheduleById,
                        this.entranceStore.clearOrderOptionsAfterOrder,
                        this.entranceStore.externalShopperNotificationId,
                        this.entranceStore.externalShopperCardId,
                        this.entranceStore.isKioskMode.get(),
                        this.entranceStore.orderComment,
                        this.entranceStore.orderDestinationAddress
                    );

                    const shoppingCartStore =
                        new ShoppingCartStore(
                            this.bridge,
                            currentOrderService,
                            this.currentPlaceService,
                            this,
                            this.openProduct);

                    shoppingCartStore.add(
                        productConfiguration,
                        amount
                    );

                    await this.currentOrderService.checkOrder();

                    await this.openOrderBuilder(
                        await this.createOrderBuilderStore(shoppingCartStore)
                    );
                });
        }
        else
        {
            await this.currentOrderService.add(productConfiguration, amount);
        }
    }

    hasProductRecommendations(
        triggeringProductId: number,
        productRecommendationMoment: ProductRecommendationMoment,
    ): boolean
    {
        const productRecommendationLists = this.getProductRecommendationLists(triggeringProductId);
        for (const productRecommendationList of productRecommendationLists)
        {
            if (productRecommendationList.moment === productRecommendationMoment)
                if (this.hasVisibleRecommendations(productRecommendationList))
                    return true;
        }
        return false;
    }

    getProductRecommendationStore(triggeringProductId: number): ProductRecommendationStore | null
    {
        const productRecommendationLists = this.getProductRecommendationLists(triggeringProductId);
        const nonEmptyProductRecommendationLists = productRecommendationLists
            .filter(list => this.hasVisibleRecommendations(list));

        if (nonEmptyProductRecommendationLists.length > 0)
           return new ProductRecommendationStore(triggeringProductId, nonEmptyProductRecommendationLists);
        else
            return null;
    }

    async openProductRecommendations(triggeringProductId: number): Promise<ProductRecommendationStore>
    {
        const store = this.getProductRecommendationStore(triggeringProductId);

        if (store === null)
            throw new IllegalArgumentException(
                `No commendations found for a Product with id ${triggeringProductId}`
            );

        await this.bridge.navigator.pushScreen(Screens.ProductRecommendations, store);
        return store;
    }

    async openProductRecommendationsIfPresent(triggeringProductId: number): Promise<void>
    {
        const store = this.getProductRecommendationStore(triggeringProductId);

        if (store !== null)
            await this.bridge.navigator.pushScreen(Screens.ProductRecommendations, store);
    }

    addProductToShoppingCart(
        productConfiguration: ProductConfiguration,
        diffInQuantity: number,
        comment: string | undefined,
    ): void
    {
        this.currentOrderService
            .add(
                productConfiguration,
                diffInQuantity,
            );
        if (this.currentOrderService.comment === undefined)
        {
            this.currentOrderService.setComment(comment);
        }
        else
        {
            const currentComment = this.currentOrderService.comment;
            this.currentOrderService.setComment(`${currentComment}\n${comment}`);
        }
    }

    startOrderPayment(order: Order)
    {
        if (order.paymentUrl)
        {
            return this.bridge.linking.canOpenUrl(order.paymentUrl)
                .then(canOpen =>
                {
                    if (canOpen)
                    {
                        return this.bridge.linking.openUrl(order.paymentUrl);
                    }
                    else
                    {
                        return Promise.resolve();
                    }
                });
        }

        return Promise.resolve();
    }

    async onOrder(order: Order): Promise<void>
    {
        if (order.hasOpenPayment)
            await this.orderService.startOrderPayment(order);
        else
            await this.resetNavigationAndOpenActiveOrders();
    }

    async resetNavigationAndOpenActiveOrders(): Promise<void>
    {
        if (this.entranceStore.navigateToBusinessRootAfterOrder.value)
        {
            await this.bridge.navigator.popScreensUntil(
                this.bridge.navigator.getSingleOpenScreenInstance(Screens.Business),
            );

            await this.bridge.navigator.truncateFuture();
        }

        await this.openHistory(true);
    }

    /**
     * Runs when the user commands the UI the application should leave the {@link Business}. This
     * does not mean the application will actually leave.
     */
    onCommandLeave(force: boolean = false): Promise<void>
    {
        const navigator = this.bridge.navigator;
        const entranceInstance = navigator.getSingleOpenScreenInstance(Screens.Entrance)!;
        const isMenuOpen = this.isMenuOpen;
        const currentPlaceService = this.currentPlaceService;
        const scaninService = this.scaninService;
        return flow(function * () {
            const goalScreenInstanceReached = yield navigator.popScreensUntil(entranceInstance, force);
            isMenuOpen.set(false);
            if (goalScreenInstanceReached) {
                yield navigator.truncateFuture();
                currentPlaceService.setScannedBusinessAndPlace(undefined, undefined);
                scaninService.currentScanin.set(undefined);
            }
        })();
    }

    openSlidePanel()
    {
        this.setSlidePanelOpened(true);
    }

    closeSlidePanel()
    {
        this.isSlidePanelOpen = false;
    }

    setSlidePanelOpened(isOpen: boolean)
    {
        this.isSlidePanelOpen = isOpen;
    }

    toggleMenuOpen()
    {
        this.isMenuOpen.set(!this.isMenuOpen.get());
    }

    setMenuOpen(isOpen: boolean)
    {
        this.isMenuOpen.set(isOpen);
    }

    openMenu()
    {
        this.setMenuOpen(true);
    }

    closeMenu()
    {
        this.setMenuOpen(false);
    }

    openOrderBuilder(orderBuilderStore?: OrderBuilderStore): Promise<OrderBuilderStore>
    {
        const usedOrderBuilderStore = orderBuilderStore
            ?
            orderBuilderStore
            :
            this.orderBuilderStore;
        return this
            .push(
                Screens.OrderBuilder,
                usedOrderBuilderStore)
            .then(() => usedOrderBuilderStore);
    }

    openComoRewardsPage(): Promise<ComoRewardsStore>
    {
		return this
			.push(
				Screens.ComoRewards,
				this.comoRewardsStore
			)
			.then(() => this.comoRewardsStore);
    }

    callback(): Promise<boolean>
    {
        return new Promise((resolve, reject) => {
            try {
                return confirm(
                    this.bridge,
                    (this.bridge.navigator as WebNavigator).scrollTo,
                    !this.entranceStore.hashOfLockedPlace
                        ? this.bridge.localizer.translate('Client-Order-Leave-Confirmation')
                        : this.bridge.localizer.translate('Client-Order-Leave-Pin'),
                    () =>
                    {
                        try
                        {
                            this.entranceStore.changeHashOfLockedPlace(undefined);
                            resolve(true);
                        }
                        catch (error)
                        {
                            reject(error);
                        }
                    },
                    null,
                    () => resolve(false),
                    this.entranceStore.hashOfLockedPlace && new PinCodeDialogInputSpec(
                    pin => Promise.resolve(pin === HARD_CODED_PIN_CODE),
                    4));
            } catch (e) {
                reject(e);
            }
        });
    }

    async openHistory(force: boolean = false): Promise<HistoryStore>
    {
        if (force || (!this.entranceStore.hideOrderHistory && this.orderService.orders.length > 0))
        {
            this.isMenuOpen.set(false);

            await this.push(
                Screens.History,
                this.historyStore,
            );
        }

        return this.historyStore;
    }

    openProfile(): Promise<ProfileStore>
    {
        this.isMenuOpen.set(false);

        return this.push(
            Screens.Profile,
            this.profileStore)
            .then(() => Promise.resolve(this.profileStore));
    }

    orderWaiter()
    {
        return confirm(
            this.bridge,
            (this.bridge.navigator as WebNavigator).scrollTo,
            this.bridge.localizer.translate('Client-Order-CallWaiter-Confirmation'),
            () => {
                placeProductOrder({
                        client_name: this.currentOrderService?.orderClientName,
                        new_payment_methods: true,
                        os: getBackendOSValue(this.bridge.client),
                        client_phone_number: this.currentOrderService?.orderClientPhoneNumber,
                        client_email: this.currentOrderService?.orderClientEmail,
                        longitude: this.currentPlaceService.locationService.longitude,
                        latitude: this.currentPlaceService.locationService.latitude,
                    },
                    {
                        paymentMethodId: 'traditional',
                        lines: [
                            {
                                type: 'request_waiter',
                            },
                        ],
                        destination_type: 'PLACE',
                        placeId: this.place.id,
                        adyenCheckoutPaymentInformation: undefined,
                    })
                    .then(this.onOrder);
            },
            this.bridge.localizer.translate('Client-Order-CallWaiter'));
    }

    orderBill()
    {
        return confirm(
            this.bridge,
            (this.bridge.navigator as WebNavigator).scrollTo,
            this.bridge.localizer.translate('Client-Order-RequestBill-Confirmation'),
            () => {
                placeProductOrder({
                        client_name: this.currentOrderService?.orderClientName,
                        new_payment_methods: true,
                        os: getBackendOSValue(this.bridge.client),
                        client_phone_number: this.currentOrderService?.orderClientPhoneNumber,
                        client_email: this.currentOrderService?.orderClientEmail,
                        longitude: this.currentPlaceService.locationService.longitude,
                        latitude: this.currentPlaceService.locationService.latitude,
                    },
                    {
                        paymentMethodId: 'traditional',
                        lines: [
                            {
                                type: 'request_bill',
                            },
                        ],
                        destination_type: 'PLACE',
                        placeId: this.place.id,
                        adyenCheckoutPaymentInformation: undefined,
                    })
                    .then(this.onOrder);
            },
            this.bridge.localizer.translate('Client-Order-RequestBill'));
    }

    hasAllergen(allergen: string): boolean
    {
        return this.profileStore.hasAllergen(allergen);
    }

    needsToSeeNutritionFlag(nutritionFlag: NutritionFlag): boolean
    {
        return this.profileStore.needsToSeeNutritionFlag(nutritionFlag);
    }

    openChildCategory(childCategory: ProductCategory): Promise<MenuStore>
    {
        const menuStore = this.menuStore;
        const childStore = menuStore.childMenuStoreByCategory.get(childCategory);
        if (childStore === undefined)
            throw new IllegalArgumentException(
                `Trying to open nonexistent category ${childCategory.id}`
            );
        return this
            .push(Screens.Menu, childStore)
            .then(() => childStore);
    }

    setPlaceSession(placeSession: PlaceSession | undefined): void
    {
        this.placeSession = placeSession;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    private initializeExitBusinessOnIdle()
    {
        EntranceStore.exitBusinessOnIdle.define(
            () => this.bridge.client.visibility,
            visibility => {
                if (visibility === 'visible')
                {
                    const now = new Date();
                    const endOfLastScanin = this.currentPlaceService.endOfLastInPlace;
                    if (endOfLastScanin !== undefined)
                    {
                        const msAgo = now.getTime() - endOfLastScanin.getTime();
                        const scaninExpiryDuration = this.place?.scaninExpiryDuration;

                        if (scaninExpiryDuration !== undefined
                            && msAgo > (1000 * toSeconds(scaninExpiryDuration)))
                        {
                            this.onCommandLeave(true);
                        }
                    }
                }
            },
        );
    }

    private async createOrderBuilderStore(shoppingCartStore: ShoppingCartStore): Promise<OrderBuilderStore>
    {
        const orderBuilderStore = new OrderBuilderStore(
            this.bridge,
            this.currentPlaceService,
            this.currentOrderService,
            this.currentAgeVerificationService,
            this.entranceStore.clockService,
            this.bridge.localizer,
            this.currentPlaceService.locationService,
            this.entranceStore,
            this,
            shoppingCartStore,
            this.orderService,
            this.pop,
            this.bridge.client
        );

        await orderBuilderStore.initialize();

        return orderBuilderStore;
    }

    public hasVisibleRecommendations(productRecommendationList: ProductRecommendationList): boolean
    {
        return productRecommendationList.productRecommendations
            .map(productRecommendation => this.productById.get(productRecommendation.recommendedProductId))
            .filter(productRecommendation => productRecommendation !== undefined)
            .some(product => product.isVisibleNow(this.place, this.bridge.clockService));
    }
}
