import { makeStyles } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { useTranslate } from '../../../../../../Bridge/Localization/useTranslate';
import { Screens } from '../../../../../../Constants/ScreenConstants';
import { useRootContext } from '../../../../../../RootContext';
import { getFileUrl } from '../../../../../../Util/Api/Resources/getFileUrl';
import { ActionButton } from '../../../../../UI/action-button/ActionButton';
import { OrderBuilderStore } from '../../OrderBuilderStore';

const useStyles = makeStyles(theme => ({
	loyaltyIntegrationLogo: {
		maxHeight: 100,
		width: 100,
	},
	root: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		gap: theme.spacing(2),
		width: '100%',
	},
}));

interface ComoRewardOrderBuilderSegmentGoToRewardsSectionProps
{
	store: OrderBuilderStore;
}

export const ComoRewardOrderBuilderSegmentGoToRewardsSection: FC<ComoRewardOrderBuilderSegmentGoToRewardsSectionProps> =
	({
		store,
	}) =>
	{
		const {navigator} = useRootContext(true);
		const translate = useTranslate();

		const comoRewardsStore = useObserver(() => store.businessStore.comoRewardsStore);
		const loyaltyIntegrationLogoUrl = useObserver(() => store.businessStore.loyaltyIntegrationLogoUrl);
		const currentScreenId = useObserver(() => navigator.currentScreenInstance.screen.id);

		const classes = useStyles();

		return <div
			className={classes.root}
		>
			{
				loyaltyIntegrationLogoUrl !== undefined &&
				<img
					alt="loyalty"
					className={classes.loyaltyIntegrationLogo}
					src={getFileUrl(loyaltyIntegrationLogoUrl)}
				/>
			}
			<ActionButton
				id="go-to-rewards"
				onClick={
					async () =>
					{
						if (currentScreenId === Screens.OrderBuilder)
							await navigator.popScreen();

						await navigator.pushScreen(
							Screens.ComoRewards,
							comoRewardsStore,
						);
					}
				}
				fullWidth
				size="large"
				text={translate('Client-Loyalty-Como-Add-Rewards')}
				variant="outlined"
			/>
		</div>;
	};
